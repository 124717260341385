<template>
  <div class="wrap">
    <div class="facilityBox">
      <!-- form表单 -->
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="企业名称：" prop="EnterpriseFullName">
          <el-input class="fromInp" v-model="manageForm.EnterpriseFullName" placeholder="输入企业名称"
            @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="手机号：" prop="LegalPersonlPhone">
          <el-input class="fromInp" v-model="manageForm.LegalPersonlPhone" placeholder="输入手机号"
            @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <el-form-item label="提交日期：" prop="TaskDatetime">
          <el-date-picker v-model="manageForm.TaskDatetime" type="daterange" range-separator="至" start-placeholder="开始日期"
            value-format="yyyy-MM-dd" end-placeholder="结束日期" @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
        <el-table-column type="index" align="center" label="序号" width="50" fixed show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="企业名称" width="220" fixed
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="企业统一社会信用代码" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="企业地址" width="330" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CreatedTime" label="提交时间" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="UpdatedTime" label="变更时间" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="StatusName" label="状态" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-tag type="success">{{ scope.row.StatusName }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Remark" label="备注" width="200" fixed="right" show-overflow-tooltip>
          <template slot-scope="scope">
            <el-alert :title="scope.row.Remark" type="warning" :closable="false" v-if="scope.row.Remark"></el-alert>
          </template>
        </el-table-column>
        <!-- <el-table-column align="center" prop="VerifyRemark" label="备注" width="150" show-overflow-tooltip>
                </el-table-column> -->
        <el-table-column align="center" label="操作" width="200" fixed="right">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="setClick(scope.row)" icon="el-icon-setting">设置
            </el-button>
            <el-button type="primary" size="small" @click="goFacility(scope.row, pagination.page)"
              icon="el-icon-document">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 10px">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" width="60%" class="deep_dialog" v-if="dialogVisible">
      <span slot="title">设置分成比例</span>
      <el-tabs v-model="dialogName" @tab-click="handleClicks($event)" style="margin-top: 1vh">
        <el-tab-pane label="设置服务商费率" name="first">
          <div class="dialog_box" style="position: relative">
            <el-popover placement="left-start" width="250" trigger="hover">
              <span>温馨提示：</span><br />
              1.请客服查阅并保存平台、服务商之间协议；<br />
              2.所有结算费率若不填写，默认为法定税率；<br />
              若填写X%，则该服务商与平台导流的货主间业务，统一按X%获得收益；<br />
              该服务商与自行推荐的货主间业务，统一按服务商-货主间费率获得收益；<br />
              3.平台技术服务费费率若不填写，默认为0.30%；
              <i class="el-icon-question" style="
                  position: absolute;
                  right: 10px;
                  top: 5px;
                  color: red;
                  font-size: 14px;
                " slot="reference"></i>
            </el-popover>
            <div class="dialogRole">
              <strong>当前角色：</strong><u>服务商</u><br />
            </div>
            <div class="dialogInfo">
              <div>
                <strong>注册ID：</strong><u>{{ tenant.LegalPersonlPhone }} </u><br />
                <strong>企业名称：</strong><u> {{ tenant.EnterpriseFullName }}</u><br />
                <strong>身份证号码：</strong><u> {{ tenant.LegalPersonIIDCard }}</u><br />
              </div>
              <div>
                <strong>姓名： </strong><u>{{ tenant.LegalPersonIName }} </u><br />
                <strong>电话：</strong><u>{{ tenant.LegalPersonlPhone }} </u><br />
                <strong>信用代码：</strong><u> {{ tenant.EnterpriseCreditCode }}</u><br />
              </div>
            </div>
            <el-table :data="tenant.eInvoiceTypeList" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              v-loading="loading" max-height="450px">
              <el-table-column align="center" label="发票-业务-场景">
                <template slot-scope="scope">
                  <span v-if="scope.row.TaskSceneName">{{ scope.row.InvoiceTypeName }} /
                    {{ scope.row.TaskTypeName }} /
                    {{ scope.row.TaskSceneName }}</span>
                  <span v-else>{{ scope.row.InvoiceTypeName }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="平台-服务商结算费率" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input class="fromInp" v-model="scope.row.Rate" placeholder="输入服务商结算费率（固定扣点）" maxlength="5"
                    onkeyup="value=value.replace(/[^0-9]+(\.?)+([0-9]{3})$|[^0-9]+(\..?)$|00/,'')"
                    oninput="if(value > 99 || value < 0 ){value = ''}">
                    <template slot="append">%</template>
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column align="center" label="技术服务费费率" show-overflow-tooltip>
                <div slot-scope="scope" style="display: flex; align-items: center">
                  <el-input class="fromInp" v-model="scope.row.TecRate" placeholder="输入技术服务费费率" maxlength="5"
                    onkeyup="value=value.replace(/[^0-9]+(\.?)+([0-9]{3})$|[^0-9]+(\..?)$|00/,'')"
                    oninput="if(value > 99 || value < 0 ){value = ''}">
                    <template slot="append">%</template>
                  </el-input>
                  <div style="
                      display: flex;
                      align-items: center;
                      color: #1890ff;
                      cursor: pointer;
                      margin-left: 3px;
                    " @click="setSameValue(scope.row.TecRate)" v-if="scope.row.TecRate">
                    <span>同步</span>
                    <i class="el-icon-refresh-right" style="margin: 2px 0px 0px 2px"></i>
                  </div>
                </div>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="设置代理商费率" name="second">
          <div class="dialog_box" style="position: relative">
            <el-popover placement="left-start" width="250" trigger="hover">
              <span>温馨提示：</span><br />
              1.请客服查阅并保存平台、代理商之间协议；<br />
              2.确定平台、代理商之间的各类型发票结算费率；<br />
              3.当前费率仅用于保存记录，实际计算线下处理；<br />
              <i class="el-icon-question" style="
                  position: absolute;
                  right: 10px;
                  top: 5px;
                  color: red;
                  font-size: 14px;
                " slot="reference"></i>
            </el-popover>
            <div class="dialogRole">
              <strong>当前角色：</strong><u>代理商</u><br />
            </div>
            <div class="dialogInfo">
              <div>
                <strong>注册ID：</strong><u>{{ tenant.LegalPersonlPhone }} </u><br />
                <strong>企业名称：</strong><u> {{ tenant.EnterpriseFullName }}</u><br />
                <strong>身份证号码：</strong><u> {{ tenant.LegalPersonIIDCard }}</u><br />
              </div>
              <div>
                <strong>姓名： </strong><u>{{ tenant.LegalPersonIName }} </u><br />
                <strong>电话：</strong><u>{{ tenant.LegalPersonlPhone }} </u><br />
                <strong>信用代码：</strong><u> {{ tenant.EnterpriseCreditCode }}</u><br />
              </div>
            </div>
            <el-table :data="eInvoiceTypeListAgent" :header-cell-style="{ color: '#666', background: '#f0f0f0' }"
              v-loading="loading" max-height="450px">
              <el-table-column align="center" label="发票-业务-场景">
                <template slot-scope="scope">
                  <span v-if="scope.row.TaskSceneName">
                    {{ scope.row.InvoiceTypeName }} /
                    {{ scope.row.TaskTypeName }} /
                    {{ scope.row.TaskSceneName }}</span>
                  <span v-else> {{ scope.row.InvoiceTypeName }}</span>
                </template>
              </el-table-column>
              <el-table-column align="center" label="平台-服务商结算费率" show-overflow-tooltip>
                <template slot-scope="scope">
                  <el-input class="fromInp" v-model="scope.row.Rate" placeholder="输入代理商结算费率（固定扣点）" maxlength="5"
                    onkeyup="value=value.replace(/[^0-9]+(\.?)+([0-9]{3})$|[^0-9]+(\..?)$|00/,'')"
                    oninput="if(value > 99 || value < 0 ){value = ''}">
                    <template slot="append">%</template>
                  </el-input>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-tab-pane>
        <el-tab-pane label="设置开票最大金额" name="third">
          <div class="dialog_box" style="position: relative">
            <el-popover placement="left-start" width="250" trigger="hover">
              <span>温馨提示：</span><br />
              1.客服将先和市场部、财务部沟通确认此服务商缴纳的保证金；<br />
              2.客服再次和服务商沟通确认可开票金额,然后设置；<br />
              3.服务商每个月（每月1日00:00至下个月1日00:00）的开票总额不能超过（小于等于）设置的【当月可开票最大金额】
              即【当月累计已开票金额】+【当月剩余开票金额】≤【当月可开票最大金额】；<br />
              4.设置完，且平台与服务商均同意合作后，立即生效
              <i class="el-icon-question" style="
                  position: absolute;
                  right: 10px;
                  top: 5px;
                  color: red;
                  font-size: 14px;
                " slot="reference"></i>
            </el-popover>
            <el-form :inline="true" style="width: 100%; text-align: center">
              <el-form-item label="当月开票最大金额(￥)：" label-width="162px">
                <el-input class="fromInp" v-model="TotalInvoiceAmount" placeholder="输入当月开票最大金额" maxlength="12"
                  oninput="if(value <= 0 ){value = ''}" style="width: 250px; color: #000" @input="inputAmount">
                  <template slot="append">.00</template>
                </el-input>
              </el-form-item>
              <el-form-item label="" prop="">
                <el-input class="fromInp" v-model="money" placeholder="人民币" oninput="if(value < 0 ){value = ''}"
                  style="width: 420px" disabled @input="inputAmount">
                </el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button v-if="dialogName == 'first'" @click="dialogVisible = false">取 消</el-button>
        <el-button v-else @click="toLastStep">上一步</el-button>
        <el-button v-if="dialogName == 'first' || dialogName == 'second'" type="primary" @click="toNextStep">下一步
        </el-button>
        <el-button v-else type="primary" @click="UpdateRate()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { _convertCurrency } from "@/utils/utils";
import { SupplierList, GetSupplierRate, SetSupplierRate } from "@/api/newRate";
export default {
  data() {
    return {
      // 最大金额
      TotalInvoiceAmount: "",
      // 分成模式弹窗
      dialogVisible: false,
      // 选中的分成模式
      dialogName: "first",
      // 分页数据
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 筛选条件数据
      manageForm: {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      },
      // 数据列博鳌
      tableData: [],
      // 设置状态
      ConfigStatus: 0,
      // 单条数据信息
      tenant: "",
      money: "",
      loading: true,
      eInvoiceTypeListAgent: [],
    };
  },
  activated() {
    // 获取列表数据
    this.BySupplierLists();
  },
  created() {
    // 数据列表
    // this.BySupplierLists();
  },
  methods: {
    searchChange() {
      this.search();
    },
    // 当月开票最大金额
    inputAmount(e) {
      this.money = _convertCurrency(e);
    },
    // 点击上一步
    toLastStep() {
      if (this.dialogName == "second") {
        this.dialogName = "first";
      } else {
        this.dialogName = "second";
      }
    },
    // 点击下一步
    toNextStep() {
      if (this.dialogName == "first") {
        this.nextClick(this.tenant.eInvoiceTypeList);
      } else {
        this.dialogName = "third";
      }
    },
    // tab切换
    handleClicks(e) {
      if (e.index == "0") {
        this.dialogName = "first";
      } else if (e.index == "1") {
        this.nextClick(this.tenant.eInvoiceTypeList);
      } else {
      }
    },
    // 设置发票费率
    nextClick(rateData) {
      let rateIndex = rateData.length - 1;
      rateData.forEach((item, index) => {
        // DefaultRate 是否有值
        if ((item.DefaultRate ?? "") !== "") {
          // 判读输入的值是否和费率相等
          if (Number(item.Rate) < Number(item.DefaultRate)) {
            // 判断是否为最后一个数据
            if (rateIndex == index) {
              this.dialogName = "second";
            }
          } else {
            this.$confirm(
              "建议：服务商的结算费率应小于发票费率。如您坚持设置此数值，请与各相关方协商好。谢谢~",
              "提示",
              {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
              }
            )
              .then(() => {
                this.dialogName = "second";
              })
              .catch(() => {
                this.dialogName = "first";
              });
          }
        }
      });
    },
    // 设置相同技术服务费费率
    setSameValue(val) {
      this.tenant.eInvoiceTypeList = this.tenant.eInvoiceTypeList.map((it) => {
        it.TecRate = val;
        return it;
      });
    },
    // 设置分成比例
    UpdateRate() {
      const loading = this.$loading({
        lock: true,
        text: "设置中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      let rateData = [];
      this.tenant.eInvoiceTypeList.forEach((item) => {
        rateData.push({
          taskSceneTypeID: item.TaskSceneTypeID,
          rateID: item.RateID,
          rate: item.Rate != 0 && item.Rate != null ? Number(item.Rate) : null,
          tecRate:
            item.TecRate != 0 && item.TecRate != null
              ? Number(item.TecRate)
              : null,
        });
      });
      let rateDataAgent = [];
      this.eInvoiceTypeListAgent.forEach((item) => {
        rateDataAgent.push({
          taskSceneTypeID: item.TaskSceneTypeID,
          rateID: item.RateID,
          rate: item.Rate != 0 && item.Rate != null ? Number(item.Rate) : null,
          tecRate: item.TecRate,
        });
      });
      let data = {
        userID: this.tenant.UserID,
        optionID: this.tenant.OptionID,
        totalInvoiceAmount: this.TotalInvoiceAmount,
        supplierRate: rateData,
        agentRate: rateDataAgent,
      };
      SetSupplierRate(data)
        .then((res) => {
          this.search();
          this.dialogVisible = false;
        })
        .finally(() => {
          loading.close();
        });
    },
    // 显示分成比例弹窗
    setClick(item) {
      this.tenant = item;
      this.dialogName = "first";
      GetSupplierRate({ userid: item.UserID }).then((res) => {
        let ratelist = [];
        let eInvoiceTypeListAgent = [];
        res.data.SupplierRate.forEach((item) => {
          item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
          item.TecRate =
            item.TecRate == 0 || item.TecRate == null ? 0.3 : item.TecRate;
          ratelist.push(item);
        });
        res.data.AgentRate.forEach((item) => {
          item.Rate = item.Rate == 0 || item.Rate == null ? "" : item.Rate;
          eInvoiceTypeListAgent.push(item);
        });
        this.TotalInvoiceAmount = res.data.TotalInvoiceAmount;
        this.money = _convertCurrency(res.data.TotalInvoiceAmount);
        this.tenant.eInvoiceTypeList = ratelist;
        this.eInvoiceTypeListAgent = eInvoiceTypeListAgent;
        this.dialogVisible = true;
      });
    },
    // 清空搜索条件
    resetForm() {
      this.manageForm = {
        EnterpriseFullName: "",
        LegalPersonlPhone: "",
        TaskDatetime: ["", ""],
      };
      this.search();
    },
    // 搜索
    search() {
      this.pagination.page = 1;
      this.BySupplierLists();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.pagination.page = e;
      this.BySupplierLists();
    },
    // 设置服务商分成比例列表
    BySupplierLists() {
      this.loading = true;
      let data = {
        enterpriseFullName: this.manageForm.EnterpriseFullName,
        legalPersonlPhone: this.manageForm.LegalPersonlPhone,
        createdTimeStart:
          this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[0]
            ? this.manageForm.TaskDatetime[0] + " 00:00:00"
            : "",
        createdTimeEnd:
          this.manageForm.TaskDatetime && this.manageForm.TaskDatetime[1]
            ? this.manageForm.TaskDatetime[1] + " 23:59:59"
            : "",
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        status: this.ConfigStatus,
      };
      SupplierList(data)
        .then((res) => {
          this.tableData = res.data.DataList;
          this.pagination.total = res.data.TotalCount;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    // 详情跳转
    goFacility(item, pageIndex) {
      this.$router.push({
        path: "/auditInfo/auditService/setServiceInfo",
        query: {
          UserID: item.UserID,
          pageIndex,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.wrap {
  .el-input.is-disabled .el-input__inner {
    color: #333;
  }
}
</style>

<style lang="scss" scoped>
.mdoeBox {
  width: 100%;
  background: #fff;
  border-radius: 10px;
  font-size: 16px;
  text-align: center;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px;
}

.mdoeDiv {
  width: 80%;
  margin: 10px 0 0;
  line-height: 26px;
  font-size: 16px;
  font-weight: 600;
  margin-left: 10%;
  color: #333;

  span {
    color: red;
    font-size: 16px;
  }
}

.dialog_box {
  font-size: 14px;
  line-height: 26px;
  width: 100%;
  background: #f8f8f8;
  padding: 20px 2%;
  box-sizing: border-box;
  color: #666;
  border-radius: 5px;

  .dialogRole {
    width: 100%;
    text-align: center;
  }

  .dialogInfo {
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-bottom: 16px;
  }
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 0px 20px 20px;
  }
}
</style>
